.t-condensed {
  font-family: 'Open Sans', serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-variation-settings: 'wdth' 0.75;
}

.t-heading-large {
  font-size: 3.125em; // 50px
  font-weight: 700;
  line-height: 1.1em;
}

.t-heading {
  font-size: 2.25em; // 36px
  font-weight: 300;
  line-height: 1.1em;
}

.t-heading--bold {
  font-weight: 800;
}

.t-heading-medium {
  font-size: 1.5em; // 18px
  line-height: 1.1em;
  font-weight: 700;
  color: var(--color-heading-small);
}

.t-heading-small {
  font-size: 1.125em; // 18px
  line-height: 1.1em;
  font-weight: 700;
  color: var(--color-heading-small);
}

.t-heading-small--light {
  font-weight: 400;
}

.t-form-heading {
  font-size: 1.0625em; // 17px
  line-height: 1.1em;
  font-weight: 700;
  color: var(--color-form-heading);
}

.t-body {
  font-size: 0.875em; // 14px
  line-height: 1.1em;
}

.t-body--spaced {
  line-height: 1.4em;
}

.t-italic {
  font-style: italic;
}

.t-bold {
  font-weight: 700;
}

.t-body-large {
  font-size: 1.125rem; // 18px
  line-height: 1.1rem;
  color: var(--color-heading-small);
}

.t-body-small {
  font-size: 0.75em;
  line-height: 1.1em;
}

.t-form-label {
  font-size: 0.625em; // 10px
  line-height: 1.1em;
}

.t-form-label--large {
  font-size: 1em;
  line-height: 1.6em;
}

.t-form-select-large {
  font-size: 20px;
  font-weight: 600;
}

.t-form-hint {
  font-size: 0.6875em; // 11px
  list-style: 1.1em;
}

.t-form-error {
  color: var(--global-input-error);
  font-weight: 700;
}

.t-link {
  color: var(--default-theme);
  text-decoration: none;

  &:visited {
    color: var(--default-theme);
  }

  &:hover {
    text-decoration: underline;
    color: var(--global-link-hover-color);
  }
}

.t-link--nav {
  font-size: 1.25em; // 20px
  line-height: 1.1em;
  color: var(--default-theme);

  @media screen and (min-width: 800px) {
    font-size: 0.875em; // 14px
    color: var(--black);

    &:visited,
    &:hover,
    &:focus {
      color: var(--black);
    }
  }
}

.t-button-inline {
  color: var(--default-theme);
  font-weight: 700;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
