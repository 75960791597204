.form__input {
  border: var(--global-input-border);
  block-size: 1.875em;
}

.form__label {
  display: inline-flex;
  flex-direction: column;
  color: var(--global-label);

  &:has(.form__label__text) {
    gap: var(--s-spacing);
  }

  &:has(.form__label__hint) {
    .form__label__text {
      order: 1;
    }

    .form__label__input {
      order: 2;
    }

    .form__label__hint {
      order: 3;
    }
  }
}

.form__label--checkbox {
  flex-direction: row;
  align-items: start;

  &:has(.form__label__text) {
    gap: var(--xxs-spacing);
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .form__label__text {
    padding-block-start: 2.5px;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.form__label__input,
.form__label__select {
  padding-inline: var(--xxs-spacing);
  block-size: 1.875em; // 30px
  border-radius: 0.5em;
  border: var(--global-input-border);
  box-sizing: content-box;

  &::placeholder {
    color: var(--global-form-placeholder-color);
  }

  &:user-invalid,
  &.ng-invalid.ng-touched {
    border-color: var(--global-input-error);
  }

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    appearance: textfield;
  }

  .form__input:read-only {
    background-color: var(--global-input-readonly-background);
    outline: none;
  }

  .form__input--light:read-only {
    background-color: var(--global-input-readonly-background-light);
    outline: none;
  }

  + .t-form-hint {
    margin-block-start: var(--xxs-spacing);
  }
}

.form__label__input:read-only {
  background-color: var(--global-input-readonly-background);
  outline: none;
}

.form__label__input--light:read-only {
  background-color: var(--global-input-readonly-background-light);
  outline: none;
}

.form__label__input-wrapper--currency {
  position: relative;

  &::before {
    position: absolute;
    inset-block-start: 50%;
    padding-inline-start: var(--xxs-spacing);
    transform: translateY(-50%);
    content: '$';
  }

  .form__label__input {
    padding-inline: calc((var(--xxs-spacing) * 2) + 0.5ch) 0;
  }
}

.form__label__input--large {
  padding: var(--m-spacing);
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
  border: none;
  inline-size: 100%;
  box-sizing: border-box;
  min-inline-size: 25px; // 25 to allow text input to shrink
}

.form__label__select--large {
  font-size: 16px;
  padding: var(--m-spacing);
  font-weight: 600;
  border-radius: 10px;
  inline-size: 100%;
  box-sizing: border-box;
  block-size: auto;
}
