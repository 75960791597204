.modal {
  border: 0;
  border-block-start: 4px solid var(--global-theme);
  box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
  inline-size: 600px;
  padding: var(--s-spacing);
  box-sizing: border-box;
  position: fixed;
  overflow: auto;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
  margin: 0;

  &::backdrop {
    background-color: rgb(0 0 0 / 50%);
  }
}

.modal--content-centered {
  text-align: center;

  .modal__info__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.modal--full-screen {
  &::backdrop {
    background-color: rgb(0 64 64);
  }
}

.modal__close-button {
  padding: var(--xs-spacing);
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;

  &.button-inline {
    border-radius: 50%;
    outline-offset: -8px;
  }
}

.modal__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-block: var(--xs-spacing);
}

.modal__header--icon {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    'icon heading'
    'icon text';
  padding-block: var(--xs-spacing);
  padding-inline-end: var(--xs-spacing);
}

.modal__header__icon {
  grid-area: icon;
  align-self: center;
  margin-inline-end: var(--xs-spacing);
}

.modal__header__heading {
  grid-area: heading;
  font-size: 1.3em;
  line-height: 1.25em;
  font-weight: 700;
  color: var(--default-theme);
  margin-inline-start: var(--xs-spacing);
}

.modal__header__text {
  grid-area: text;
  margin-inline-start: var(--xs-spacing);
  margin-block-start: var(--xs-spacing);
}

.modal__info {
  padding: 0 var(--xs-spacing) var(--xs-spacing);
}

.modal__info__title {
  margin-block-start: var(--s-spacing);
  color: var(--global-theme);
}

.modal__info__title--denied {
  color: var(--global-error);
}

.modal__info__description {
  margin-block-start: 2px;
  text-transform: uppercase;
  font-size: 1.25em;
  line-height: 1.1em;
  color: var(--dark-text);

  span {
    display: block;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.modal__info__buttons {
  margin-block-start: var(--m-spacing);
  display: flex;
  gap: var(--m-spacing);
  flex-direction: column;

  @media (min-width: 550px) {
    flex-direction: row;
  }
}
