html {
  scrollbar-gutter: stable;
  overflow-y: scroll;
}

body:has(dialog[open]) {
  overflow: hidden;
}

.app-root {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-block-size: 100vh;
}

@media screen and (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}
