.button-reset {
  border: 0;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  border-radius: 0;
  background-color: transparent;
  align-items: start;
}

.button {
  background-color: var(--global-button-background-color);
  border: 1px solid var(--global-button-background-color);
  border-radius: 0.5rem; // 8px
  block-size: 3.25rem; // 52px
  line-height: 1.5rem; // 24px
  inline-size: 60%;
  font-size: 0.875rem; // 14px
  padding: var(--s-spacing);
  max-inline-size: 25rem; // 400px
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  color: var(--black);

  &[disabled] {
    opacity: 0.65;
    background-color: var(--global-button-background-color-disabled);
    border-color: var(--global-button-background-color-disabled);
    color: var(--black);
    cursor: not-allowed;
  }

  &:hover,
  &:focus,
  &:focus-visible {
    &:not([disabled]) {
      background-color: var(--global-button-background-hover-color);
      text-decoration: none;
    }
  }
}

.button--denied {
  background-color: var(--white);
  color: var(--charge-denied);
  border-color: var(--charge-denied);

  &[disabled] {
    background-color: var(--white);
  }

  &:hover,
  &:focus,
  &:focus-visible {
    &:not([disabled]) {
      background-color: var(--white);
    }
  }
}

.button--full-width {
  inline-size: 100%;
}

.button-secondary {
  background-color: var(--global-button-background-color-secondary);
  border: 1px solid var(--global-button-background-color-secondary);
  color: var(--white);
  border-radius: 0.5rem; // 8px
  block-size: 3.25rem; // 52px
  line-height: 1.5rem; // 24px
  inline-size: 60%;
  font-size: 0.875rem; // 14px
  padding: var(--s-spacing);
  max-inline-size: 25rem; // 400px
  display: inline-flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;

  &[disabled] {
    opacity: 0.65;
    background-color: var(--global-button-background-color-disabled);
    border-color: var(--global-button-background-color-disabled);
    color: var(--black);
    cursor: not-allowed;
  }

  &:hover,
  &:focus,
  &:focus-visible {
    &:not([disabled]) {
      background-color: var(--global-button-background-hover-color-secondary);
      text-decoration: none;
    }
  }
}

.button-secondary--outline {
  background-color: transparent;
  color: var(--global-button-background-color-secondary);
  cursor: pointer;
  text-decoration: none;

  &[disabled] {
    opacity: 0.65;
    border-color: var(--global-button-background-color-disabled);
    color: var(--black);
    cursor: not-allowed;
  }

  &:hover,
  &:focus,
  &:focus-visible {
    &:not([disabled]) {
      background-color: transparent;
      border-color: var(--global-button-background-hover-color-secondary);
      color: var(--global-button-background-hover-color-secondary);
      text-decoration: none;
    }
  }
}

.button-secondary--full-width {
  inline-size: 100%;
}

.button-inline {
  background-color: transparent;
  border: 0;
  color: inherit;
  border-radius: 0; // 8px
  font-size: inherit; // 14px
  padding: 0;
  display: inline;
  font-weight: inherit;
  cursor: pointer;
  text-decoration: none;

  &[disabled] {
    cursor: not-allowed;
  }

  &:hover,
  &:focus,
  &:focus-visible {
    &:not([disabled]) {
      text-decoration: underline;
    }
  }
}
