.visually-hidden {
  inline-size: 1px;
  block-size: 1px;
  position: absolute;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
}

.ignore-layer {
  display: contents;
}
