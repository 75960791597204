/* You can add global styles to this file, and also import other style files */
@use './styles/reset';
@use './styles/variables';
@use './styles/base';
@use './styles/helpers';
@use './styles/buttons';
@use './styles/type';

// Angular A11y
@use '@angular/cdk/a11y-prebuilt';

// Modules
@use './styles/modules/forms';
@use './styles/modules/modals';

#splash {
  background: white;
  position: absolute;
  margin: auto;
  inset-block-start: 0;
  inset-inline-start: 0;
  inline-size: 100%;
  block-size: 100%;
  z-index: 99999;
  opacity: 1;

  #splash-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    inline-size: 100%;
    block-size: 100%;
    user-select: none;
  }

  .hidden {
    display: none;
  }

  .shown {
    display: block;
  }
}

.loading-message,
.error-message {
  color: black;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.sub-message {
  margin-block-start: -4px;
  color: black;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
}

#splash-loading {
  opacity: 1;
  display: block;
}

#splash-loading.hidden {
  display: none;
  opacity: 0;
}

.hidden {
  display: none;
}

.shown {
  display: block;
}

// Needed for payment forms to prevent double scroll on windows FF (very hard to repro bug, be sure you've fixed it before removing)
.form-container {
  display: contents;
}
